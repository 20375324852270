import '../common';

import 'bootstrap/dist/js/bootstrap.min';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/home.css';
import '../components/advantage.css';
import '../components/client.css';
import '../components/columnized.css';
import '../components/feature';
import '../components/footer';
import '../components/heading.css';
import '../components/logo';
import '../components/section';
import '../components/post.css';
import '../components/subscribe_form_top.css';
import '../components/feedback_form.css';
import '../components/tile';

import Header from '../components/header';
import HeaderSearch from '../components/header-search';
import Button from '../components/button';
import Subscribe from '../components/subscribe';
const GitHubButtons = require('../modules/github-buttons');

document.addEventListener('DOMContentLoaded', () => {
  const getScrollY = () => window.pageYOffset || document.documentElement.scrollTop;

  const handleScroll = () => {
    document.documentElement.classList.toggle('has-scrolled', getScrollY() > 0);
  };

  /**
   * Scroll
   */
  handleScroll();
  window.addEventListener('scroll', () => handleScroll());

  /**
   * Header
   */
  const header = new Header();
  header.init();

  /**
   * Search
   */
  if (document.querySelector('.js-header-search')) {
    const search = new HeaderSearch();
    search.init();
  }

  /**
   * Buttons
   */
  const buttons = new Button();
  buttons.init();

  /**
   * Subscribe
   */
  const subscribe = new Subscribe();
  subscribe.init();

  GitHubButtons.render();
});

window.addEventListener('load', () => {
  document.documentElement.classList.add('is-app-ready');
});
